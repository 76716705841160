import React, { ErrorInfo } from "react";
import WarningCircleBlue from "../../Assets/Images/warningCircleBlue.svg";
import "./UnAuthorizedScreen.scss";

interface IErrorBoundaryState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    // Todo: Once the API Backend will ready then pass these errors to the APIs
    // You can log the error or send it to an error reporting service
    // console.log(error.message, "error"); // Main Error Message
    // console.log(error.cause, "error");
    // console.log(error.stack, "error"); // STack
    // console.log(errorInfo.componentStack, "errorInfo");
  }

  render() {
    if (this.state.hasError) {
      // You can render an error message or fallback UI here
      return (
        <div className="container-fluid">
          <div className="warning-notes">
            <img src={WarningCircleBlue} alt="WarningImg" />
            <h6>Something went wrong.</h6>

          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
