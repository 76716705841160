import React from "react";
import { Navigate, Outlet, useOutletContext, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {isEmpty, find} from "lodash";
import { IFullTripDetails, fetchFullTripDetailsByTripId } from "../../../service/trip";
import { ROUTE_URLS, buildURL } from "../../../constant/routeURLs";
import { FINANCE_PERMISSION, TRIP_PERMISSONS } from "../../../constant/permissions";
import TripDetailsHeader from "./Header";
import TripSummary from "./Summary";
import Loader from "../../../components/common/Loader";
import Tabs from "../../../components/atoms/tab/tab";
import "./trip.scss";
import "../../../pages/contact/contact-list/contact-screens.scss";
import "../../../pages/JetCardAgreementExternal/jet-card-agreement.scss";

export enum TRIP_DETAILS_TAB_LIST {
    DETAILS = "details",
    PASSENGERS = "passengers",
    FINANCES = "finances",
    TASKS = "tasks",
    NOTES = "notes",
    ACTIVITY_HISTORY = "activity-history",
};

interface ITab {
    permission: boolean;
    title: string,
    key: TRIP_DETAILS_TAB_LIST,
    navigateUrl: string
}

type TripDetailsContextType = { tripDetail: IFullTripDetails, fetchTripDetails: () => void };

const TripDetails: React.FC = () => {
    const { tripId } = useParams();
    const location = useLocation();
    const [tripDetail, setTripDetail] = React.useState<IFullTripDetails | null>(null);
    const [isError, setIsError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [tabList, setTabList] = React.useState<Array<ITab>>([]);
    const [selectedTab, setSelectedTab] = React.useState<string | null>(null);

    const { permission } = useSelector((state: any) => state.auth);

    React.useEffect(() => {
        if (!isEmpty(tabList) && location.pathname) {
            const selectedTab = find(tabList, {navigateUrl: location.pathname});
            setSelectedTab(selectedTab ? selectedTab.key : tabList[0].key);
        }
    }, [tabList, location]);
    
    React.useEffect(() => {
        fetchTripDetails();
    }, [tripId]);

    
    const onFetchDetailTabRender = (tripDetail: IFullTripDetails | null) => {
      let rowData: Array<ITab> = [
        {
          permission: true,
          title: "Trip Details",
          key: TRIP_DETAILS_TAB_LIST.DETAILS,
          navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS, { tripId }),
        },
        {
          permission: true,
          title: "Passengers",
          key: TRIP_DETAILS_TAB_LIST.PASSENGERS,
          navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS_PASSENGERS_TAB, {
            tripId,
          }),
        },
        {
          permission: Boolean(tripDetail?.permissions[FINANCE_PERMISSION.VIEWTRIPFINANCEDETAIL]),
          title: "Finances",
          key: TRIP_DETAILS_TAB_LIST.FINANCES,
          navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS_FINANCES_TAB, {
            tripId,
          }),
        },
        {
          permission: true,
          title: "Tasks",
          key: TRIP_DETAILS_TAB_LIST.TASKS,
          navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS_TASKS_TAB, { tripId }),
        },
        {
          permission: permission.permissions[0].includes(
            TRIP_PERMISSONS.VIEWTRIPNOTES
          ),
          title: "Notes",
          key: TRIP_DETAILS_TAB_LIST.NOTES,
          navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS_NOTES_TAB, { tripId }),
        },
        // TO DO: Hiding this as trip details should not be shown as of now
        // {
        //   permission: true,
        //   title: "Activity History",
        //   key: TRIP_DETAILS_TAB_LIST.ACTIVITY_HISTORY,
        //   navigateUrl: buildURL(ROUTE_URLS.TRIP_DETAILS_ACTIVITY_HISTORY_TAB, {
        //     tripId,
        //   }),
        // },
      ];
      setTabList(rowData.filter((tab) => tab.permission));
    }


    const fetchTripDetails = React.useCallback(() => {
        setIsLoading(true)
        fetchFullTripDetailsByTripId(Number(tripId), (tripDetail, isError) => {
            if (isError) {
                setIsError(true);
                setIsLoading(false);
                return;
            }
            setTripDetail(tripDetail);
            setIsLoading(false);
            onFetchDetailTabRender(tripDetail);
        });
    }, [tripId]);
    
    if (isLoading) {
        return (
            <div className="container-fluid">
                <Loader />
            </div>
        );
    }

    if (isError) {
        return <Navigate to="/403" replace />
    }

    return (
        <div className="container-fluid" data-testid='contact-detail-container'>
            <TripDetailsHeader tripDetail={tripDetail} />
            <div className="userDetailsWrapper">
                {tripDetail && (
                    <div className="row">
                        <TripSummary tripDetail={tripDetail} />
                        <div className="col-lg-8 col-xl-9">
                            <Tabs isLink activeKey={selectedTab} dataTestId="tab-id" tabList={tabList}>
                                {selectedTab && (
                                    <Outlet context={{ tripDetail, fetchTripDetails }} />
                                )}
                            </Tabs>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default React.memo(TripDetails);

export const useTripDetails = () => {
    return useOutletContext<TripDetailsContextType>();
}