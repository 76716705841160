import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserAuthenticated } from "../utils/auth";
import Header from "./header/Header";
import Sidebar from "../components/atoms/sidebar/sidebar";
import { KnockFeedProvider } from "@knocklabs/react-notification-feed";
import NotificationProvider from "../providers/NotificationProvider";

const Layout: React.FC = () => {
  const { permission } = useSelector((state: any) => state.auth);
  const { userAzurAD } = useSelector((state: any) => state.auth);

  return isUserAuthenticated() && permission ? (
    <React.Fragment>
      {userAzurAD && (
        <KnockFeedProvider
          apiKey={String(process.env.REACT_APP_KNOCK_API_KEY)}
          feedId={String(process.env.REACT_APP_FEED_ID)}
          userId={userAzurAD?.azure_ad}
        >
          <NotificationProvider />
        </KnockFeedProvider>
      )}
      <Sidebar>
        <Header />
        <div>
          <Outlet />
        </div>
      </Sidebar>
    </React.Fragment>
  ) : (
    <Navigate to={"/login"} replace={true} />
  );
};

export default Layout;
