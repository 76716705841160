import React from "react";
import { find, filter } from "lodash";
import { IFullTripDetails, ITripPassenger } from "../../../service/trip";
import { fetchTimezoneBaseOnAddress } from "../../../service/thirdParty";
import { TRIP_STATUS_MAP_LIST } from "../../../constant/constant";
import { formatAddress } from "../../../utils/utils";
import BagIcon from "../../../Assets/Images/bag-blue.svg";
import UserIcon from "../../../Assets/Images/user-blue-ico.svg";
import AddressIcon from "../../../Assets/Images/marker-blue.svg";
import TimeIcon from "../../../Assets/Images/clock-blue.svg";
import PinIcon from "../../../Assets/Images/pin-blue.svg";
import AircraftTakeOff from "../../../Assets/Images/aircraftTakeOff.svg";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface ITripSummary {
    tripDetail: IFullTripDetails;
}

const TripSummary: React.FC<ITripSummary> = ({tripDetail}) => {
    const { client, tripNotes, legs } = tripDetail;
    const clientAddress = formatAddress(client.streetAddress, client.city, client.state, client.country);
    const pinnedNotes = filter(tripNotes, { isPinned: true });
    const [timezone, setTimezone] = React.useState('');

    React.useEffect(() => {
        fetchTimezoneBaseOnAddress(clientAddress, (response: string | null, isError: boolean) => {
            if (response && !isError) {
                setTimezone(response)
            }
        });
    }, [clientAddress]);

    const distinctPassengersCount = React.useMemo(() => {
        const passengersList: Array<ITripPassenger> = [];
        legs.forEach(leg => {
            leg.passengers.forEach(passenger => {
                const isAlreadyAdded = find(passengersList, { id: passenger.id });
                if (!isAlreadyAdded) {
                    passengersList.push(passenger);
                }
            });
        });
        return passengersList.length;
    }, [legs]);

    const getBrokersAndHandlers = React.useMemo(() => {
        const brokerNames: Array<string> = [];
        const handlerNames: Array<string> = [];
        tripDetail.brokers.forEach(broker => {
            if (broker.isHandler) {
                handlerNames.push(broker.name);
            } else {
                brokerNames.push(broker.name);
            }
        });
        return { brokerNames, handlerNames };
    }, [tripDetail.brokers]);

    return (
        <div className="col-lg-4 col-xl-3">
            <div className="userSortInfoCard">
                <div className="userPicTitleSec">
                    <div className="uploadUserPic">
                        <img src={AircraftTakeOff} alt="profile-pic" />
                    </div>
                    <div className="uploadTopInfoSec">
                    <Tooltip
                content={(props) => (
                  <>
                    {tripDetail.tripId}
                  </>
                )}
                position="bottom"
              >
                 <h4 title={tripDetail.tripId}>{tripDetail.tripId}</h4>
                       
              </Tooltip>
                        <div className="contactStatusTypeDetails">
                            <div className="tripStatusIndicator">
                                <span className={`statusIndicator ${TRIP_STATUS_MAP_LIST[tripDetail.status.id].class}`} />
                                {TRIP_STATUS_MAP_LIST[tripDetail.status.id].display_name_new}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="userContactInfo">
                    <p className="mt10">
                        <img src={UserIcon} alt="Client's First and Last Name" />
                        <label>{tripDetail.client.name}</label>
                    </p>
                    <p>
                        <img src={BagIcon} alt="Organization Name and Title" />
                        <label>{tripDetail.client.businessName}</label>
                    </p>
                    <p style={{marginTop: '34px'}}>
                        <img src={AddressIcon} alt="Client's Address" />
                        <label>{clientAddress}</label>
                    </p>
                    <p>
                        <img src={TimeIcon} alt="Client's Timezone" />
                        <label>{timezone}</label>
                    </p>
                </div>
                <hr />
                <div className="userTripInfoSec">
                    <p className="mt10">
                        <span>Total Legs</span>
                        <label>{tripDetail.legs.length}</label>
                    </p>
                    <p>
                        <span>Total Passengers</span>
                        <label>{distinctPassengersCount}</label>
                    </p>
                    <hr />
                    <p className="mt10">
                        <span>Broker: </span>
                        <label>{getBrokersAndHandlers.brokerNames.join(', ')}</label>
                    </p>
                    <p>
                        <span>Handled By: </span>
                        <label>{getBrokersAndHandlers.handlerNames.join(', ')}</label>
                    </p>
                </div>
            </div>
            <div className="pinned-notes-wrapper">
                {pinnedNotes.map(note => (
                    <div key={note.id} className="pinned-notes-section">
                        <section className="pinnednotes-icon">
                            <img src={PinIcon} width={16} alt="Trip's Note" />
                        </section>
                        <section className="pinnednotes-description">
                            <p className="pinnednote-text">{note.notes}</p>
                        </section>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default React.memo(TripSummary);