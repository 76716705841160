import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Badge } from "@progress/kendo-react-indicators";
import { Switch } from "@progress/kendo-react-inputs";
import Scrollbars from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import useAxios from "../../service/Service";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { updateNotification } from "../../redux/action/notification";
import { INotification, fetchNotifications, markAllAsRead } from "../../service/notification";



const isDateOfThisWeekAndLastWeek = (inputDate: any, format: any) => {
  // get the start and end of the current week
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");

  // get the start and end of the previous week
  const startOfLastWeek = moment().subtract(1, "week").startOf("week");
  const endOfLastWeek = moment().subtract(1, "week").endOf("week");

  // convert the input date to a moment object
  const date = moment(inputDate, format);

  if (date.isBetween(startOfWeek, endOfWeek)) {
    return true;
  } else if (date.isBetween(startOfLastWeek, endOfLastWeek)) {
    return false;
  } else {
  }
};

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const { totalUnread, primaryNotifications: notifications, notifications: allNotification } = useSelector((state: any) => state.notification);
  const setTotalUnread = (unread: any) => {
    dispatch(updateNotification({totalUnread: unread}));
  }
  const [onlyUnread, setOnlyUnread] = useState<boolean>(false);

  const MarkAllAsReadHandler = async () => {
    const { response, status } = await markAllAsRead();

    if (status === 200 || status === 201) {
      dispatch(
        updateNotification({
          totalUnread: 0,
          notifications: allNotification.map((not: INotification) => {
            not.isVisited = true;
            return not;
          }),
          primaryNotifications: notifications.map((not: INotification) => {
            not.isVisited = true;
            return not;
          })
        }),
      );
    }
  };

  const GetNotificationTime = ({ time }: any) => {
    const format = "yyyy-MM-DD[T]HH:mm:ss.SSSZ";
    const notificationDateTime = moment.tz(time, format, 'UTC'); // Parse time as UTC
  
    const localNotificationDateTime = notificationDateTime.clone().local(); // Convert to local time
  
    const localNotificationTime = localNotificationDateTime.format("HH:mm A");
  
    const lastWeekSunday = moment()
      .subtract(1, "week")
      .startOf("week")
      .diff(moment(), "days");
  
    if (localNotificationDateTime.isSame(moment(), "day")) {
      return <span>Today at {localNotificationTime}</span>;
    } else if (localNotificationDateTime.isSame(moment().subtract(1, "day"), "day")) {
      return <span>Yesterday at {localNotificationTime}</span>;
    } else if (
      localNotificationDateTime.isAfter(moment().subtract(-lastWeekSunday, "days"))
    ) {
      if (isDateOfThisWeekAndLastWeek(time, format)) {
        return (
          <span>
            This {localNotificationDateTime.format("dddd")} at {localNotificationTime}
          </span>
        );
      } else {
        return (
          <span>
            Last {localNotificationDateTime.format("dddd")} at {localNotificationTime}
          </span>
        );
      }
    } else {
      return <span>{localNotificationDateTime.format("dddd, MMMM DD, yyyy")}</span>;
    }
  };

  const numberOfUnreadNotification = () => {
    return totalUnread;
  };

  const filterNotifications = (unread: boolean) => {
    return notifications.filter((not: any) => {
      if (unread) {
        return !Boolean(not.isVisited);
      } else {
        return true;
      }
    });
  };


 const getNotifications = (read = false) => {
  fetchNotifications(20, 0, read, (notifications) => {
    dispatch(updateNotification({ primaryNotifications: notifications }));
  });
 }

  const NotificationList = () => {
    return (
      <ul>
        {notifications &&
          filterNotifications(onlyUnread).map((not: INotification, index: number) => {
            const nameArr = (not?.createdByName ?? "").split(" ");
            const firstName = nameArr[0];
            const lastName = nameArr.length > 1 && nameArr[1];
            return (
              <li key={`notification-li-${not.id}-${index}`}>
                <span className="k-button-text">
                  <div className="userPicSec">
                    {!Boolean(not?.isVisited) && <Badge />}
                    <div className="userPic">
                      <span className="inititialsImg">
                        {firstName && firstName.charAt(0).toUpperCase()}
                        {lastName && lastName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </span>
                <div className="userDataSec">
                  <h6>{not?.message}</h6>
                  <span className="dateTime">
                    <GetNotificationTime time={not.createdDate} />
                  </span>
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div className="notificationSec">
      <div className="dropdown">
        <Button fillMode="link" className="notificationBtn" type="submit">
          {numberOfUnreadNotification() > 0 && (
            <Badge themeColor="error">
              {numberOfUnreadNotification() <= 9
                ? numberOfUnreadNotification()
                : "9+"}
            </Badge>
          )}
        </Button>
        <div className="dropdown-menu">
          <div className="notificationTitleSec">
            <h5>Notifications</h5>
            <div className="readUnreadSwitch">
              <Switch
                checked={onlyUnread}
                onChange={() => {
                  setOnlyUnread(!onlyUnread);
                  getNotifications(!onlyUnread)
                }}
              />
              <label>Unread Only</label>
            </div>
            <Button
              fillMode="link"
              className="btn-link"
              type="button"
              onClick={() => {
                MarkAllAsReadHandler();
              }}
            >
              Mark all as read
            </Button>
          </div>
          <Scrollbars className="notificationScrollSec" autoHide>
            <NotificationList />
          </Scrollbars>
          <div className="allNotification">
            <Button
              fillMode="link"
              className="btn-link"
              type="submit"
              onClick={() => {
                navigate("/notifications");
              }}
            >
              View All Notifications
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
