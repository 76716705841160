import { isEmpty } from "lodash";
import { parsePhoneNumber } from 'react-phone-number-input'
import { sumAmount } from "../pages/trip/manageTrip/createTrip/stepsForm/proposalForm/calculations";
import { ILeg } from "../pages/trip/manageTrip/createTrip/stepsForm/proposalForm/interface/ILeg";
import { TRIP_STATUS_MAP_LIST, usCountryCode } from "../constant/constant";
import { CONTACT_TYPE, IS_TRIP_CANCELLD, TRIP_STATUS_KEY } from "../constant/enum";
import moment from "moment";
import { ITask } from "../service/task";
import { mapValues, flatten, groupBy, orderBy } from "lodash";
// import { getWeatherByLatAndLng } from "../service/weather";

interface Fee {
  id: number;
  name: string;
  amount: number;
}

export const breakPoint: DataType = {
  xSmall: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

type DataType = {
  xSmall: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

export const getContactFullName = (personalInformation: any) => {
  return personalInformation.contactType === CONTACT_TYPE.INDIVIDUAL
    ? `${personalInformation.firstName || ""} ${personalInformation.lastName || ""}`
    : ` ${personalInformation.businessName || ""}`;
};

export const redirectToExternalUrl = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const toDataURL = (url: any) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const currencyFormat = (value: any, format?: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const isArrayLengthGreaterThanOne = (arr: any) => {
  try {
    return Array.isArray(arr) && arr.length > 0;
  } catch (e) {
    return false;
  }
};

export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const americanConventionFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formateNumber = (value: number | null) => {
  return value ? americanConventionFormatter.format(value) : "$0.00";
};

export const convertCurrencyToNumber = (value:any) => {
  return Number(value?.replace(/[^0-9\.]+/g,""))
}

export const isValidHttpUrl = (url: string) => {
  // Check for http or https
  const urlRegex: RegExp = /^(?:(?:https?|www)\:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  // Check for www
  const urlRegex1: RegExp = /^(www\.[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)|(https?:\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/;
  return urlRegex.test(url) || urlRegex1.test(url);
};

export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export function getAbsoluteValue(num: any) {
  try{
    return Math.abs(num);
  } catch(e) {
    return 0;
  }
}

export function validatePhoneNumber(value: string) {
  const parsedNumber = parsePhoneNumber(`+${value}`);
  if(parsedNumber?.countryCallingCode == "1") {
    return !(value.length < 11)
  } else {
    return Boolean(parsedNumber?.isValid());
  }
}

export function maskPhoneNumber(phoneNumber: string): string {
  if(Boolean(phoneNumber)) {
  const number = parsePhoneNumber(`+${phoneNumber}`);
  if(number?.countryCallingCode) {
    return `+${number.countryCallingCode} (${number.nationalNumber.substring(0, 3)}) ${number.nationalNumber.substring(3, 6)}-${number.nationalNumber.substring(6)}`
  }
  const strippedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove all non-numeric characters
  let formattedPhoneNumber = '';

  if (strippedPhoneNumber.length === 10) { // US phone number
    formattedPhoneNumber = strippedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else if (strippedPhoneNumber.length > 10) { // International phone number
    const countryCode = strippedPhoneNumber.substring(0, strippedPhoneNumber.length - 10);
    const areaCode = strippedPhoneNumber.substring(countryCode.length, countryCode.length + 3);
    const localNumber = strippedPhoneNumber.substring(countryCode.length + 3);
    formattedPhoneNumber = `+${countryCode} (${areaCode}) ${localNumber.slice(0, 3)}-${localNumber.slice(3)}`;
  } else { // Invalid phone number
    formattedPhoneNumber = strippedPhoneNumber;
  }

  return formattedPhoneNumber;
  }
  else {
    return "";
  }
}

export function formatAddressRender(item: any) {
  const USA_COUNTRY_ID = 233;
  const stateName = item?.country?.id != USA_COUNTRY_ID
  ? item?.addressesTypes?.stateName || ""
  : item?.state?.displayName || "";
  const address = [(item.address || "") , (item?.address2 || ""), (item?.city || ""), (stateName), (item?.zip || ""), (item?.country?.displayName || "")].filter((val) => !isEmpty(val.trim()));
  return address.join(", ")
}

export function formatAddress(streetAddress: string | null, city: string | null, state: string | null, country: string | null) {
  const address = [(streetAddress || ""), (city|| ""), (state || ""), (country || "")].filter((val) => !isEmpty(val.trim()));
  return address.join(", ");
}

export const formatNumberToFixed = (num: number) => {
  if (Number.isInteger(num)) {
    return num.toString();
  } else {
    return num.toFixed(2);
  }
}

export const feeCalculation = (data: Fee[]) => {
  const Handling_Fee = data?.find((item: Fee) => item?.id === 1)?.amount || 0
  const Segment_Fee = data?.find((item: Fee) => item?.id === 2)?.amount || 0
  const Federal_Excise_Tax = data?.find((item: Fee) => item?.id === 3)?.amount || 0
  const International_Fee = data?.find((item: Fee) => item?.id === 4)?.amount || 0
  const Special_Request = data?.filter((item: Fee) => ![1, 2, 3, 4].includes(item?.id)).map((value: Fee) => value?.amount || 0)
  return {
    Handling_Fee: Handling_Fee,
    Segment_Fee: Segment_Fee,
    Federal_Excise_Tax: Federal_Excise_Tax,
    International_Fee: International_Fee,
    Special_Request: sumAmount(Special_Request)
  }
};

export const clientPayStatus = (dollars: number = 0,adjustment: number = 0) => (dollars + adjustment) !== 0;

export const downloadFileFromS3 = async (file: string, fileName: string) => {
  const responseData = await fetch(file);
  const blob = await responseData.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    const dataURL = reader.result;
    const anchor: any = document.createElement("a");
    anchor.href = dataURL;
    anchor.download = fileName;
    anchor.click();
  };
};

export enum LegSourceType {
  DESTINATION = "DESTINATION",
  ORIGIN = "ORIGIN",
}

export const getLegtFormatedAddress = (singleLeg: ILeg, legSourceType: LegSourceType) => {
  let addr1: any = ""
  let addr2: any  = "";
  let addr3: any = "";

  if(legSourceType === LegSourceType.ORIGIN) {
    addr1 = singleLeg?.originAirport?.airportAddress?.city;
    addr2 = singleLeg?.originAirport?.airportAddress?.country.id === usCountryCode ? singleLeg?.originAirport?.airportAddress?.state?.abbreviation : singleLeg?.originAirport?.airportAddress?.country?.countryCode;
    addr3 = singleLeg?.originAirport?.code;
  } else {
    addr1 = singleLeg?.destinationAirport?.airportAddress?.city;
    addr2 = singleLeg?.destinationAirport?.airportAddress?.country?.id === usCountryCode ? singleLeg?.destinationAirport?.airportAddress?.state?.abbreviation : singleLeg?.destinationAirport?.airportAddress?.country?.countryCode;
    addr3 = singleLeg?.destinationAirport?.code;
  }
  
  let fullAddress = "";
  if(addr1 && addr2) {
    fullAddress = `${addr1}, ${addr2}`;
  } else if(addr1 && !addr2) {
    fullAddress =  addr1;
  } else if(addr2 && !addr1) {
    fullAddress =  addr2;
  }
  return fullAddress + (addr3 ? `(${addr3})` : '');
}

export const checkIsLegDisableByTripStatusId = (statusId : number) => {
    const tripStatusDisableList = [
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.BROKER_TO_REVIEW].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.COMPLETED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.FINALIZED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id,
    ];
    return tripStatusDisableList.includes(statusId);
}

export const tripCancelWithCost = (statusId: any) => {
  return [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id, TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id].includes(statusId);
}

export const checkString = (value: any) => {
  if(typeof value === "string") {
    return value;
  } else {
    return "";
  } 
}

export const checkNumber = (value: any) => {
  if(typeof value === "number") {
    return value;
  } else {
    return 0;
  } 
}

export const checkBoolean = (value: any) => {
  if(typeof value === "boolean") {
    return value;
  } else {
    return false;
  } 
}
export const isTripCancelled = (trip: any): IS_TRIP_CANCELLD => {
  const isCancelTripWithoutCost = [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED].id].includes(trip.tripStatusId);
  const isCancelTripWithCost = [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id, TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id].includes(trip.tripStatusId);
  if(!isCancelTripWithCost && !isCancelTripWithoutCost) {
    return IS_TRIP_CANCELLD.NO;
  } else {
    return isCancelTripWithCost ? IS_TRIP_CANCELLD.CANCELLED_WITH_COST : IS_TRIP_CANCELLD.CANCELLED_WITHOUT_COST;
  }
}

export const MAX_ALLOWED_EXPORT_ROWS = 25000;
export const MAX_ALLOWED_EXPORT_ROWS_MESSAGE = "Can’t export more than 25000 records. Please filter the records accordingly."

export const loadScript = (src: string) => new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
        type: "text/javascript",
        defer: true,
        src,
        onerror: (e: any) => {
            reject(e);
        }
    });
    scriptElem.onload = () => {
        resolve();
    };
    document.body.appendChild(scriptElem);
});

export const getExpiryDates = () => {
  const years: number[] = [];
  const months: string[] = [
    "01", "02", "03", "04", "05", "06",
    "07", "08", "09", "10", "11", "12"
  ];
  
  const currentYear = new Date().getFullYear();

  for (let i = 0; i < 7; i++) {
    years.push(currentYear + i);
  }

  return {
    months,
    years
  };
};


export function sortTasksByDueDateAndPriority(tasks: Array<ITask>) {
  // Define the priority order
  const priorityOrder = {
    High: 1,
    Medium: 2,
    Low: 3,
  };

  // Group tasks by due date
  const groupedTasks = groupBy(tasks, (task) =>
    moment(task.dueDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
  );

  // Sort tasks within each group by priority
  const sortedTasks = mapValues(groupedTasks, (group) =>
    orderBy(group, [(task) => priorityOrder[task.priority]], ['asc'])
  );

  // Flatten the grouped and sorted tasks back into a single array
  const result = flatten(Object.values(sortedTasks));

  return result;
}

export const convertArrayIntoString = (array: Array<string>) => {
  return array.filter((item) => !isEmpty(item)).join(", ");
}
//TODO: For future implementation
// interface IGetWeatherDetail {
//   lat: string,
//   lng: string,
//   departureTime: string,
// }
// export async function getWeatherDetail({lat, lng, departureTime}: IGetWeatherDetail) {
//   const weatherData = await getWeatherByLatAndLng(lat, lng);

// }
