import React from "react";
const viewportContext:any = React.createContext({});
// type AllProps={
//   children:React.ReactNode
// }
export const ViewportProvider = ({ children }:any) => {
  const [width, setWidth]:any = React.useState(window.innerWidth);
  const [height, setHeight]:any = React.useState(window.innerHeight);
  const handleWindowResize:any = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const { width, height }:any = React.useContext(viewportContext);
  return { width, height };
};