export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const DELETE_POST = "DELETE_POST";
export const SET_LOADER = "SET_LOADER";
export const FETCH_POSTS_DATA = "FETCH_POSTS_DATA";

export const CREATE_ROLE = "CREATE_ROLE";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const DELETE_ROLE = "DELETE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";

export const CREATE_PASSENGER = "CREATE_PASSENGER";
export const GET_ALL_PASSENGER = "GET_ALL_PASSENGER";
export const DELETE_PASSENGER = "DELETE_PASSENGER";
export const EDIT_PASSENGER = "EDIT_PASSENGER";
export const GET_PASSENGER_BY_ID = "GET_PASSENGER_BY_ID";
export const GET_ALL_COUNTRY="GET_ALL_COUNTRY"
export const GET_ALL_STATE="GET_ALL_STATE"
export const GET_ALL_PASSENGER_EXPANDED="GET_ALL_PASSENGER_EXPANDED"


export const CREATE_CONTACT = "CREATE_CONTACT";
export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID";
export const SET_SELECTED_TAB_INDEX = "SET_SELECTED_TAB_INDEX";

export const GET_PERSONAL_INFORMATION = "GET_PERSONAL_INFORMATION";
export const GET_CONTACT_INFORMATION = "GET_CONTACT_INFORMATION";
export const GET_BROKER_INFORMATION = "GET_BROKER_INFORMATION";
export const GET_IDENTITY_INFORMATION = "GET_IDENTITY_INFORMATION";
export const GET_SPOUSE_INFORMATION = "GET_SPOUSE_INFORMATION";
export const RESET_CONTACT_DETAIL = "RESET_CONTACT_DETAIL";
export const GET_COUNTRY_LIST_INFORMATION='GET_COUNTRY_LIST_INFORMATION'
export const GET_STATE_LIST_INFORMATION='GET_STATE_LIST_INFORMATION'


export const CREATE_TEAM = "CREATE_TEAM";
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const DELETE_TEAM = "DELETE_TEAM";
export const EDIT_TEAM = "EDIT_TEAM";
export const GET_TEAM_BY_ID = "GET_TEAM_BY_ID";

export const GET_ALL_PRIVILAGES = "GET_ALL_PRIVILAGES";

export const CREATE_USER = "CREATE_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";

export const SET_LOGIN_USER_DATA = "SET_LOGIN_USER_DATA";
export const RESET_LOGIN_USER_DATA = "RESET_LOGIN_USER_DATA";

export const HEADER_PERMISSION = "HEADER_PERMISSION";

export const JETCARD_PROMOTION_FILTER_CHANGE_HANDLER = "JETCARD_PROMOTION_FILTER_CHANGE_HANDLER";
export const JETCARD_FILTER_LOADED = "JETCARD_FILTER_LOADED";

export const JETCARD_AGREMENT_FILTER_CHANGE_HANDLER = "JETCARD_AGREMENT_FILTER_CHANGE_HANDLER";
export const JETCARD_AGREMENT_FILTER_LOADED = "JETCARD_AGREMENT_FILTER_LOADED";

export const URT_USER_CHANGE_FILTER = "URT_USER_CHANGE_FILTER";
export const URT_USER_CHANGE_DATA_STATE = "URT_USER_CHANGE_DATA_STATE";

export const CONTACT_CHANGE_FILTER = "CONTACT_CHANGE_FILTER";
export const CONTACT_CHANGE_DATA_STATE = "CONTACT_CHANGE_DATA_STATE";

export const SET_USERAZURAD = "SET_USERAZURAD";

export const TRIP_FILTER_CHANGE_HANDLER = "TRIP_FILTER_CHANGE_HANDLER";
export const TRIP_FILTER_LOADED = "TRIP_FILTER_LOADED";
export const UPDATE_TRIP_IS_LOADING = "UPDATE_TRIP_IS_LOADING";
export const UPDATE_TRIP_OVERVIEW = "UPDATE_TRIP_OVERVIEW";
export const UPDATE_TRIP_FORM_STEPS = "UPDATE_TRIP_FORM_STEPS";
export const UPDATE_SELECTED_STEP = "UPDATE_SELECTED_STEP";
export const UPDATE_IS_EDIT_AFTER_SENT = "UPDATE_IS_EDIT_AFTER_SENT";
export const UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT = "UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT";
export const UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY = "UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY";
export const UPDATE_TRIP_FORM_DUPLICATE_TRIP = "UPDATE_TRIP_FORM_DUPLICATE_TRIP";
export const RESET_TRIP = "RESET_TRIP";
export const RESET_TRIP_DETAIL = "RESET_TRIP_DETAIL";

export const INITALIZE_TRIP_INQUIRY_FORMIK_FORM = "INITALIZE_TRIP_INQUIRY_FORMIK_FORM";
export const INITALIZE_SPECIAL_REQUEST_FORMIK_FORM = "INITALIZE_SPECIAL_REQUEST_FORMIK_FORM";
export const RESET_TRIP_INQUIRY_FORMIK_FORM = "RESET_TRIP_INQUIRY_FORMIK_FORM";

export const UPDATE_TRIP_LOADING = "UPDATE_TRIP_LOADING";
export const UPDATE_TRIP_STATUS_OBJ_DRAFT = "UPDATE_TRIP_STATUS_OBJ_DRAFT";
export const UPDATE_TRIP_STATUS_OBJ_DIRTY = "UPDATE_TRIP_STATUS_OBJ_DIRTY";

export const INITIALIZE_TRIP_MANAGE_FORMIK_FORM = "INITIALIZE_TRIP_MANAGE_FORMIK_FORM";
export const RESET_TRIP_MANAGE_FORMIK_FORM = "RESET_TRIP_MANAGE_FORMIK_FORM";

export const UPDATE_TRIP_DETAIL = "UPDATE_TRIP_DETAIL";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

export const UPDATE_OPERATOR_FINANCE_LIST = "UPDATE_OPERATOR_FINANCE_LIST";
export const UPDATE_OPERATOR_FINANCE_LIST_FILTER = "UPDATE_OPERATOR_FINANCE_LIST_FILTER";
export const UPDATE_OPERATOR_FINANCE_LIST_DATA_STATE = "UPDATE_OPERATOR_FINANCE_LIST_DATA_STATE";