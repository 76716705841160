import { DELETE_USER, EDIT_USER, GET_ALL_PRIVILAGES, URT_USER_CHANGE_DATA_STATE, URT_USER_CHANGE_FILTER } from './../../constant/redux-const';
import { GET_ALL_USERS, CREATE_USER } from '../../constant/redux-const';
import loader from './loader';
import useAxios from '../../service/Service';
/** Load value of ToDo item action method */
export const getAllUsers: any = () => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, loading, error, status }: any = await useAxios({
    method: 'get',
    url: '/user/',
    headers: JSON.stringify({ accept: '*/*' }),
    body: null,
  });
  if (status == 200) {

    await dispatch({
      type: GET_ALL_USERS,
      payload: response.users ==null ? [] : response.users 
    });
    await dispatch(loader.setLoader(false));
  } else {
    await dispatch(loader.setLoader(false));
  }
};

export const getAllPermission: any = () => async (dispatch: any) => {
  
  await dispatch(loader.setLoader(true));
  const { response, loading, error, status }: any = await useAxios({
    method: 'get',
    url: '/privileges',
    headers: JSON.stringify({ accept: '*/*' }),
    body: null,
  });
  if (status == 200) {
   
    await dispatch({
      type: GET_ALL_PRIVILAGES,
      payload: response.responseData
    });
    await dispatch(loader.setLoader(false));
  } else {
    await dispatch(loader.setLoader(false));
  }
};


export const addUser: any = () => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, loading, error, status }: any = await useAxios({
    method: 'get',
    url: '/team',
    headers: JSON.stringify({ accept: '*/*' }),
    body: null,
  });
  if (status == 200) {

    await dispatch({
      type: CREATE_USER,
      payload: response.responseData
    });
    await dispatch(loader.setLoader(false));
  } else {
    await dispatch(loader.setLoader(false));
  }
};

/** Delete ToDo item action method */
export const deleteTodo: any = (id: number) => (dispatch: any) => {
  // dispatch(loader.setLoader(true));
  dispatch({
    type: DELETE_USER,
    payload: id
  });
};
/** Edit ToDo item action method */
export const editUser: any = (item: any) => (dispatch: any) => {
  // dispatch(loader.setLoader(true));
  dispatch({
    type: EDIT_USER,
    payload: item
  });
};

export const urtUserChangeDataStateHandler = (state: any) => ({
  type: URT_USER_CHANGE_DATA_STATE,
  payload: state,
});

export const urtUserChangeFilterHandler = (state: any) => ({
  type: URT_USER_CHANGE_FILTER,
  payload: state,
});