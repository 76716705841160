import moment from "moment";
import { find } from "lodash";
import { URLS } from "../utils/url";


export interface IClientIPAndLocation {
    ipAddress: string,
    continentCode: string,
    continentName: string,
    countryCode: string,
    countryName: string,
    stateProv: string,
    city: string
}
export const fetchClientIPAndLocation = async (
    callback: (response: IClientIPAndLocation | null, isError: boolean) => void
) => {
    const response = await fetch(URLS.GetIp);
    const data = await response.json();
    if (data) {
        callback(data, false);
        return
    }
    callback(null, true);
};

export const fetchTimezoneBaseOnAddress = async (
    address: string,
    callback: (response: string | null, isError: boolean) => void
) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const addressResponse = await fetch(`${URLS.GetLatLng}${address}&key=${API_KEY}`);
    const addressData = await addressResponse.json();
    if (addressData && addressData.status === 'OK' && addressData.results.length) {
        let priorityAddressComponent = find(addressData.results, result => result.types.includes('locality'));
        if (!priorityAddressComponent) {
            priorityAddressComponent = find(addressData.results, result => result.types.includes('administrative_area_level_2'));
        }
        if (!priorityAddressComponent) {
            priorityAddressComponent = find(addressData.results, result => result.types.includes('administrative_area_level_1'));
        }
        if (!priorityAddressComponent) {
            priorityAddressComponent = find(addressData.results, result => result.types.includes('country'));
        }
        if (!priorityAddressComponent) {
            priorityAddressComponent = addressData.results[0];
        }

        const { lat, lng } = priorityAddressComponent.geometry.location;
        const timezoneResponse = await fetch(`${URLS.GetTimezone}${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${API_KEY}`);
        const timezoneData = await timezoneResponse.json();
        if (timezoneData && timezoneData.status === 'OK' && timezoneData.timeZoneId && timezoneData.timeZoneName) {
            const {timeZoneId, timeZoneName} = timezoneData;
            const timezoneOffset = moment().tz(timeZoneId).format("Z");
            const timezoneDisplay = `(UTC ${timezoneOffset}) ${timeZoneName} (${timeZoneId})`;
            callback(timezoneDisplay, false);
            return;
        }
        console.error("Get_TIMEZONE_ERR: ", timezoneData.status);
        callback(null, true);
    }
    console.error("Get_LAT_LNG_ERR: ", addressData.status);
    callback(null, true);
};