import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { customeRoutes } from "./lazy-load";
import ProtectedComponent from "./protectedComponent";
import Layout from "../layout";
import ToastNotification from "../components/common/ToastNotification";
import Loader from "../components/common/Loader";
import { PermissionProtectedRoute } from "../HOCs/routesHOCs";
import { GLOBAL_PERMISSIONS } from "../constant/permissions";
import { ROUTE_URLS } from "../constant/routeURLs";
import { SYSTEM_SETTINGS_TYPE } from "../pages/URTManagement";
import { DASHBOARD_TABS } from "../pages/home";

export default function Navigation(props: any) {
  return (
    <div>
      <div>
        <Suspense fallback={<Loader/>}>
          <Routes>
            <Route path="/login" element={<customeRoutes.LoginScreen />} />
            <Route path="jetcard/external-pages/initiation/:id" element={<customeRoutes.Initiation />} />
            <Route path="jetcard/external-pages/preview/:id" element={<customeRoutes.Initiation />} />
            <Route path="jetcard/external-pages/thankyou" element={<customeRoutes.Thankyou />} />
            <Route path={ROUTE_URLS.TRIP_PROPOSAL_PREVIEW_CLIENT} element={<customeRoutes.TripProposalPreviewClient />}/>
            <Route path={ROUTE_URLS.TRIP_PROPOSAL_PREVIEW_CLIENT_RECIPIENT} element={<customeRoutes.TripProposalPreviewClientRecipient />}/>
            <Route path={ROUTE_URLS.TRIP_PROPOSAL_PRELIMINARY_INVOICE_CLIENT} element={<customeRoutes.TripProposalClientPreliminaryInvoice />}/>
            <Route path={ROUTE_URLS.TRIP_PROPOSAL_INVOICE_CLIENT} element={<customeRoutes.TripProposalClientInvoice />}/>
            <Route path={ROUTE_URLS.TRIP_FEEDBACK_CLIENT} element={<customeRoutes.TripFeedbackClient />}/>

            <Route path="/" element={<Layout />}>
              <Route path="/" element={<ProtectedComponent component={<customeRoutes.HomeScreen />} />}>
                <Route path={`${DASHBOARD_TABS.SALE}-dashboard`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_SALES_DASHBOARD]} outlet={<customeRoutes.SaleDashboard/>}/>}/>
                <Route path={`${DASHBOARD_TABS.FINANCE}-dashboard`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_FINANCE_DASHBOARD]} outlet={<customeRoutes.FinanceDashboard/>}/>}/>
                <Route path={`${DASHBOARD_TABS.LEADERSHIP}-dashboard`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_LEADERSHIP_DASHBOARD]} outlet={<customeRoutes.LeadershipDashboard/>}/>}/>
              </Route>
              
              <Route path="/system-setting" element={<customeRoutes.URTManagement />}>
                <Route index element={<Navigate replace to={`${SYSTEM_SETTINGS_TYPE.ROLE}-management`} />}/>
                <Route path={`${SYSTEM_SETTINGS_TYPE.ROLE}-management`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_ROLE_MANAGEMENT]} outlet={<customeRoutes.RoleManagement/>}/>}/>
                <Route path={`${SYSTEM_SETTINGS_TYPE.TEAM}-management`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_TEAM_MANAGEMENT]} outlet={<customeRoutes.TeamManagement/>}/>}/>
                <Route path={`${SYSTEM_SETTINGS_TYPE.USER}-management`} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEW_USER_MANAGEMENT]} outlet={<customeRoutes.UserManagement/>}/>}/>
                <Route path={`${SYSTEM_SETTINGS_TYPE.ACCOUNT}-management`} element={<customeRoutes.AccountManagement/>} />
                <Route path={`${SYSTEM_SETTINGS_TYPE.CONFIGURATION}-management`} element={<customeRoutes.ConfigurationManagement/>} />
              </Route>

              <Route path={ROUTE_URLS.CONTACT_DETAILS} element={<customeRoutes.ContactDetails />}>
                <Route index path={ROUTE_URLS.CONTACT_DETAILS} element={<customeRoutes.ContactDetailsDetailsTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_TRIPS_TAB} element={<customeRoutes.ContactDetailsTripsTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_JETCARD_TAB} element={<customeRoutes.ContactDetailsJetCardsTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_JETCARD_TAB_WITH_ID} element={<customeRoutes.ContactDetailsJetCardsTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_PASSENGERS_TAB} element={<customeRoutes.ContactDetailsPassengersTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_TASKS_TAB} element={<customeRoutes.ContactDetailsTasksTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_NOTES_TAB} element={<customeRoutes.ContactDetailsNotesTab />} />
                <Route path={ROUTE_URLS.CONTACT_DETAILS_WEBINQUIRIES_TAB} element={<customeRoutes.ContactDetailsWebInquiriesTab />} />
              </Route>

              <Route path="global-search-results" element={<customeRoutes.GlobalSearchResults/>} />
              <Route path="user-details" element={<customeRoutes.UserDetails />} />
              <Route path="create-team" element={<customeRoutes.CreateTeamScreen />} />
              <Route path="edit-team" element={<customeRoutes.CreateTeamScreen />} />
              <Route path="team" element={<customeRoutes.TeamScreen />} />
              <Route path="team-list" element={<customeRoutes.TeamList />} />
              <Route path="create-role" element={<customeRoutes.CreateRoleScreen />} />
              <Route path="edit-role" element={<customeRoutes.CreateRoleScreen />} />
              <Route path="contacts-list" element={<customeRoutes.ContactListScreen />} />

              <Route path={ROUTE_URLS.TRIP}  element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.VIEWTRIP]} outlet={<customeRoutes.TripListing />}/>} />
              <Route path={ROUTE_URLS.TRIP_DETAILS} element={<customeRoutes.TripDetails />}>
                <Route index path={ROUTE_URLS.TRIP_DETAILS} element={<customeRoutes.TripDetailsDetailsTab />} />
                <Route path={ROUTE_URLS.TRIP_DETAILS_PASSENGERS_TAB} element={<customeRoutes.TripDetailsPassengersTab />} />
                <Route path={ROUTE_URLS.TRIP_DETAILS_FINANCES_TAB} element={<customeRoutes.TripDetailsFinancesTab />} />
                <Route path={ROUTE_URLS.TRIP_DETAILS_TASKS_TAB} element={<customeRoutes.TripDetailsTasksTab />} />
                <Route path={ROUTE_URLS.TRIP_DETAILS_NOTES_TAB} element={<customeRoutes.TripDetailsNotesTab />} />
                <Route path={ROUTE_URLS.TRIP_DETAILS_ACTIVITY_HISTORY_TAB} element={<customeRoutes.TripDetailsActivityHistoryTab />} />
              </Route>
              <Route path={ROUTE_URLS.CREATE_TRIP} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.TRIP_CREATE]} outlet={<customeRoutes.ManageTrip />}/>} />
              <Route path={ROUTE_URLS.EDIT_TRIP} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.TRIP_CREATE]} outlet={<customeRoutes.ManageTrip />}/>} />
              <Route path={ROUTE_URLS.TRIP_PROPOSAL_PREVIEW_BROKER} element={<PermissionProtectedRoute permissionKeyList={[GLOBAL_PERMISSIONS.TRIP_CREATE]} outlet={<customeRoutes.TripProposalPreviewBroker />}/>} />
              <Route path={ROUTE_URLS.TRIP_FEEDBACK_BROKER} element={<customeRoutes.TripFeedbackBroker />} />
              
              {/* Finance */}
              <Route path={ROUTE_URLS.FINANCE} element={<customeRoutes.Finance />}>
                <Route index path={ROUTE_URLS.FINANCE} element={<NotFound />} />
                <Route path={ROUTE_URLS.FINANCE_OPERATOR_PAYMENT} element={<customeRoutes.FinanceOperatorPayments/>}/>
              </Route>
              <Route path={ROUTE_URLS.FINANCE_TRIP_ADMINISTRATION} element={<customeRoutes.FinanceTripAdministrationList/>}/>

              {/* Task */}
              <Route path={ROUTE_URLS.TASKS} element={<customeRoutes.Tasks/>} />

              <Route path="jet-card" element={<customeRoutes.JetCard />} />
              <Route  path="jetcard-list" element={<customeRoutes.JetCardList />} />  
              <Route  path="create-jetcard" element={<customeRoutes.CreateJetCard />} />
              <Route  path="edit-jetcard" element={<customeRoutes.CreateJetCard />} />  
              <Route  path="jetcard-detail" element={<customeRoutes.CreateJetCard />} />  
              <Route path="*" element={<NotFound />} />

              {/* Screens added by sudhir */}
              <Route  path="contacts/jetcard/promotions-list" element={<customeRoutes.JetCardPromotionsList />} />  
              <Route  path="notifications" element={<customeRoutes.Notifications />} />  
              <Route  path="contacts/passengers-list" element={<customeRoutes.PassengersList />} />  
              <Route path="jetcard/promotions-list" element={<customeRoutes.JetCardPromotionsList />} />
              <Route path="contacts/jetcard/jet-cards-list" element={<customeRoutes.JetCardAgreements />} />  

              <Route path="403" element={<customeRoutes.UnAuthorized />} />
            </Route>
          </Routes>
        </Suspense>
      </div>
      <ToastNotification />
    </div>
  );
}
function NotFound() {
  return <h2>NotFound</h2>;
}