import shortId from "short-uuid";
import moment from "moment";
import { isEmpty } from "lodash";
import { TRIP_TYPE } from "../../constant/constant";
import {
    INITALIZE_TRIP_INQUIRY_FORMIK_FORM,
    INITALIZE_SPECIAL_REQUEST_FORMIK_FORM,
    RESET_TRIP_INQUIRY_FORMIK_FORM
} from "../../constant/redux-const";
import { convertMinutesInHourAndMinutes } from "../../helper/helper";
import { IPassenger } from "../../service/contact";

export interface ILegsFormikValues {
    id: number | null
    key: string;
    originAirportId: number | null;
    originAirportTimezone: string | null;
    destinationAirportId: number | null;
    destinationAirportTimezone: string | null;
    departDate: Date;
    departTime: Date | null;
    duration: string;
    numberOfPassengers: number | string;
    passengerIds: Array<number>;
    leadPassengerId?: number;
};
export interface ISpecialRequestsFormikValues {
    key: string;
    typeId: number | null;
    name: string;
    fee: number | string;
    shortDescription: string;
    legIndex: Array<number>;
    forAllLeg: boolean;
};
export interface IAdditionalNotesFormikValues {
    id: number | null;
    key: string;
    note: string;
    isPinned: boolean; 
}
export interface IExtraFormikValues {
    preNotesSelectedList: Array<IAdditionalNotesFormikValues>;
    isRoundTrip: boolean;
}
export interface ITripInquiryFormikValues {
    clientId: number | null;
    clientEmail: string | null;
    brokerIds: Array<number>;
    handledByIds: Array<number>;
    tripTypeId: number;
    ballparkAmount?: number | string;
    jetCardId?: number | null;
    aircraftTypeIds: Array<number>;
    legs: Array<ILegsFormikValues>;
    specialRequests: Array<ISpecialRequestsFormikValues>;
    additionalNotes: Array<IAdditionalNotesFormikValues>;
    extra: IExtraFormikValues;
};

let emptyLegsObject: Array<ILegsFormikValues> = [{
    id: null,
    key: shortId.generate(),
    originAirportId: null,
    originAirportTimezone: null,
    destinationAirportId: null,
    destinationAirportTimezone: null,
    departDate: moment().toDate(),
    departTime: null,
    duration: '',
    numberOfPassengers: '',
    passengerIds: [],
    leadPassengerId: undefined,
}];
let emptySpecialRequestsObject: Array<ISpecialRequestsFormikValues>= [{
    key: shortId.generate(),
    typeId: null,
    name: '',
    fee: '',
    shortDescription: '',
    legIndex: [0],
    forAllLeg: true,
}];
let emptyAdditionalNotesObject: Array<IAdditionalNotesFormikValues> = [{
    id: null,
    key: shortId.generate(),
    note: "",
    isPinned: false
}];
let emptyExtraObject: IExtraFormikValues = {
    preNotesSelectedList: [],
    isRoundTrip: false
}

const initialState: any = {
    tripInquiryFormikForm: {
        clientId: null,
        clientEmail: null,
        brokerIds: [],
        handledByIds: [],
        tripTypeId: TRIP_TYPE[0].id,
        ballparkAmount: '',
        jetCardId: null,
        aircraftTypeIds: [],
        legs: emptyLegsObject,
        specialRequests: emptySpecialRequestsObject,
        additionalNotes: emptyAdditionalNotesObject, 
        extra: emptyExtraObject
    }
};

const formikDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INITALIZE_TRIP_INQUIRY_FORMIK_FORM: {
        if (action.payload) {
            const tripOverviewData = action.payload.overview;
            const duplicateTrip = action.payload.duplicateTrip;
            
            const brokerIds: Array<number> = [];
            const handledByIds: Array<number> = [];
            tripOverviewData.brokers.forEach((broker: any) => {
                if (broker.isHandler) {
                    handledByIds.push(broker.id);
                } else {
                    brokerIds.push(broker.id);
                }
            });

            const aircraftTypeIds = isEmpty(tripOverviewData.airCraftTypes) ? [] : tripOverviewData.airCraftTypes.map((aircraft: any) => aircraft.id);

            const legs = tripOverviewData.legs.map((leg: any) => {
                const {durationHoursString, durationMinutesString} = convertMinutesInHourAndMinutes(leg.duration);
                return {
                    id: leg.id,
                    key: leg.id,
                    originAirportId: leg.originAirport.id,
                    originAirportTimezone: leg.originAirport.timezone,
                    destinationAirportId: leg.destinationAirport.id,
                    destinationAirportTimezone: leg.destinationAirport.timezone,
                    departDate: duplicateTrip ? null : moment(leg.departDate, "MM-DD-yyyy").toDate(),
                    departTime: moment(leg.departTime, "HH:mm:ss").toDate(),
                    duration: `${durationHoursString}:${durationMinutesString}`,
                    numberOfPassengers: leg.numberOfPassengers.toString(),
                    passengerIds: leg.passengers ? leg.passengers.map((item: IPassenger) => item.id) : [],
                    leadPassengerId: leg.leadPassengerId ? leg.leadPassengerId : null,
                }
            });

            const additionalNotes = isEmpty(tripOverviewData.additionalNotes) ? emptyAdditionalNotesObject : tripOverviewData.additionalNotes.map((note: any) => ({
                ...note,
                clientId: tripOverviewData.client.id,
                key: shortId.generate()
            }));

            const isRoundTrip = tripOverviewData.legs?.length === 2 &&
            tripOverviewData.legs[0].originAirport.id === tripOverviewData.legs[1].destinationAirport.id &&
            tripOverviewData.legs[0].destinationAirport.id === tripOverviewData.legs[1].originAirport.id;
            return {
                ...state,
                tripInquiryFormikForm: {
                    clientId: tripOverviewData.client.id,
                    clientEmail: tripOverviewData.client.email,
                    brokerIds,
                    handledByIds,
                    tripTypeId: tripOverviewData.tripType.id,
                    ballparkAmount: tripOverviewData.ballparkAmount ? tripOverviewData.ballparkAmount : '',
                    jetCardId: tripOverviewData.jetCardId,
                    aircraftTypeIds: aircraftTypeIds,
                    legs: legs,
                    specialRequests: emptySpecialRequestsObject,
                    additionalNotes: additionalNotes, 
                    extra: {
                        ...emptyExtraObject,
                        isRoundTrip
                    }
                }
            };
        }
        return state;
    }
    case INITALIZE_SPECIAL_REQUEST_FORMIK_FORM: {
        if (!isEmpty(action.payload)) {
            const specialRequests = action.payload.map((specialRequest: any) => {
                return {
                    key: shortId.generate(),
                    typeId: specialRequest.typeId,
                    name: specialRequest.name,
                    fee: specialRequest.fee.toString(),
                    shortDescription: specialRequest.shortDescription,
                    legIndex: specialRequest.legIndex,
                    forAllLeg: state.tripInquiryFormikForm.legs.length === specialRequest.legIndex.length,
                }
            });
            return {
                ...state,
                tripInquiryFormikForm: {
                    ...state.tripInquiryFormikForm,
                    specialRequests,
                }
            };
        }
        return state;
    }
    case RESET_TRIP_INQUIRY_FORMIK_FORM: {
        return initialState;
    }
    default:
      return state;
  }
};

export default formikDataReducer;
