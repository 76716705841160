import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {isEmpty, find} from "lodash";
import { getAllPermission } from "../../redux/action/user";
import { GLOBAL_PERMISSIONS } from "../../constant/permissions";
import Tabs from "../../components/atoms/tab/tab";
import "./HomeScreen.scss";

export enum DASHBOARD_TABS {
    SALE = "sale",
    FINANCE = "finance",
    LEADERSHIP = "leadership"
};

interface ITab {
    title: string,
    key: DASHBOARD_TABS,
    navigateUrl: string
};

const HomeScreen: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { permission } = useSelector((state: any) => state.auth);
    const { loader } = useSelector((state: any) => state.loader);
    const [tabList, setTabList] = React.useState<Array<ITab> | null>(null);
    const [selectedTab, setSelectedTab] = React.useState<string | null>(null);

    React.useEffect(() => {
        dispatch(getAllPermission());
    }, []);

    React.useEffect(() => {
        let rowData: Array<ITab> = [];
        const roleGlobalPermission = permission.permissions[0];
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_SALES_DASHBOARD)) {
            rowData.push({title: "Sales", key: DASHBOARD_TABS.SALE, navigateUrl: `/${DASHBOARD_TABS.SALE}-dashboard` });
        }
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_FINANCE_DASHBOARD)) {
            rowData.push({title: "Finance", key: DASHBOARD_TABS.FINANCE, navigateUrl: `/${DASHBOARD_TABS.FINANCE}-dashboard` });
        }
        if (roleGlobalPermission.includes(GLOBAL_PERMISSIONS.VIEW_LEADERSHIP_DASHBOARD)) {
            rowData.push({title: "Leadership", key: DASHBOARD_TABS.LEADERSHIP, navigateUrl: `/${DASHBOARD_TABS.LEADERSHIP}-dashboard` });
        }
        setTabList(rowData);
    }, [permission]);
  
    React.useEffect(() => {
        if (tabList !== null && !isEmpty(tabList)) {
            if (location.pathname === "/") {
                navigate(tabList[0].navigateUrl);
            } else {
                const selectedTab = find(tabList, {navigateUrl: location.pathname});
                setSelectedTab(selectedTab ? selectedTab.key : tabList[0].key);
            }
        }
    }, [tabList, location]);

    return (
        <div className="container-fluid">
            <div className="dashboardWrapper">
                {tabList !== null && (
                    <React.Fragment>
                        {isEmpty(tabList) ? (
                            <p>No Dashboards available!</p>
                        ) : (
                            <Tabs isLink activeKey={selectedTab} dataTestId="tab-id" tabList={tabList}>
                                {loader ? <React.Fragment>Loading</React.Fragment> : selectedTab && (
                                    <Outlet />
                                )}
                            </Tabs>
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}

export default React.memo(HomeScreen);