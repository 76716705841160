import * as type from "../../constant/redux-const";
import { assign } from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";

const initialState: any = {
  tripFilter: {
    clientName: "",
    displayName: "",
    tripStatus: [],
    tripType: "",
    broker: [],
    airports: [],
    type_ids: [],
    aircraft_ids: [],
    operator_ids: [],
    tail_numbers: [],
    fbos: [],
    tripStartDate: "",
    tripEndDate: "",
  },
  dataState: {
    filter: {
      filters: [],
      logic: "or",
    },
    sort: [
      { field: "tripStartDate", dir: "desc" },
      { field: "id", dir: "desc" },
    ],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
  tripFrom: {
    isLoading: false,
    selectedStep: null,
    isEditAfterSent: false,
    overview: null,
    formStepList: [],
    statusObj: {
      draftSubmitCount: 0,
      isDirty: false,
    },
    duplicateTrip: false,
  },
  tripDetails: {
    isLoading: false,
    overview: null,
    statusObj: {
      draftSubmitCount: 0,
      isDirty: false,
    },
  },
};

const TripReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.TRIP_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        tripFilter: { ...action.payload },
      };
    case type.TRIP_FILTER_LOADED:
      return {
        ...state,
        dataState: action.payload,
    };
    case type.UPDATE_TRIP_IS_LOADING: {
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                isLoading: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_LOADING: {
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                isLoading: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_OVERVIEW: {
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                overview: action.payload,
            }
        };
    }
    case type.UPDATE_TRIP_FORM_STEPS:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                formStepList: action.payload
            }
        };
    case type.UPDATE_SELECTED_STEP:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                selectedStep: action.payload
            }
        };
    case type.UPDATE_IS_EDIT_AFTER_SENT:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                isEditAfterSent: action.payload
            }
        };
    case type.UPDATE_TRIP_FORM_STATUS_OBJ_DRAFT:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                statusObj: {
                    ...state.tripFrom?.statusObj,
                    draftSubmitCount: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_STATUS_OBJ_DRAFT:
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                statusObj: {
                    ...state.tripDetails?.statusObj,
                    draftSubmitCount: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_FORM_STATUS_OBJ_DIRTY:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                statusObj: {
                    ...state.tripFrom.statusObj,
                    isDirty: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_FORM_DUPLICATE_TRIP:
        return {
            ...state,
            tripFrom: {
                ...state.tripFrom,
                duplicateTrip: action.payload
            }
        };
    case type.UPDATE_TRIP_STATUS_OBJ_DIRTY:
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                statusObj: {
                    ...state.tripDetails.statusObj,
                    isDirty: action.payload
                }
            }
        };
    case type.UPDATE_TRIP_DETAIL:{
        return {
            ...state,
            tripDetails: {
                ...state.tripDetails,
                overview: action.payload
            }
        }
    }
    case type.RESET_TRIP: {
        return {
            ...state,
            tripFrom: {
                isLoading: false,
                duplicateTrip: false,
                selectedStep: null,
                overview: null,
                formStepList: [],
                statusObj: {
                    draftSubmitCount: 0,
                    isDirty: false,
                }
            },
        };
    }
    case type.RESET_TRIP_DETAIL: {
        return {
            ...state,
            tripDetails: initialState.tripDetails
        };
    }
    default:
      return state;
  }
};

export default TripReducer;
