import {
  CONTACT_CHANGE_DATA_STATE,
  CONTACT_CHANGE_FILTER,
  DELETE_CONTACT,
  EDIT_CONTACT,
  GET_ALL_CONTACT,
  GET_BROKER_INFORMATION,
  GET_CONTACT_INFORMATION,
  GET_COUNTRY_LIST_INFORMATION,
  GET_IDENTITY_INFORMATION,
  GET_PERSONAL_INFORMATION,
  GET_SPOUSE_INFORMATION,
  GET_STATE_LIST_INFORMATION,
  RESET_CONTACT_DETAIL,
} from "./../../constant/redux-const";
import loader from "./loader";
import useAxios from "../../service/Service";
/** Load value of ToDo item action method */
export const getAllContact: any = () => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, loading, error, status }: any = await useAxios({
    method: "get",
    url: "/contacts/",
    headers: JSON.stringify({ accept: "*/*" }),
    body: null,
  });

  if (status == 200) {
    await dispatch({
      type: GET_ALL_CONTACT,
      payload: response.responseData,
    });
    await dispatch(loader.setLoader(false));
  } else {
    await dispatch(loader.setLoader(false));
  }
};

export const addContact: any = (data: any) => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  await setTimeout(function () {}, 100);
  return data;
  // const { response, loading, error, status }: any = await useAxios({
  //   method: 'post',
  //   url: '/role',
  //   headers: JSON.stringify({ accept: '*/*' }),
  //   body: data,
  // });

  // if (status == 200) {
  //   await dispatch({
  //     type: CREATE_ROLE,
  //     payload: response.responseData
  //   });
  //   await dispatch(loader.setLoader(false));
  // } else {
  //   await dispatch(loader.setLoader(false));
  // }
};

/** Delete ToDo item action method */
export const deleteContact: any = (id: number) => async (dispatch: any) => {
  dispatch(loader.setLoader(true));
  const { response, loading, error, status, responseCode }: any =
    await useAxios({
      method: "delete",
      url: `/contacts/personalinfo/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });
  if (status == 200) {
    // await dispatch(loader.setLoader(false));
    return response;
  } else {
    await dispatch(loader.setLoader(false));
  }
};
/** Edit ToDo item action method */
export const editContact: any = (item: any) => (dispatch: any) => {
  // dispatch(loader.setLoader(true));
  dispatch({
    type: EDIT_CONTACT,
    payload: item,
  });
};

export const getPersonalInformation: any =
  (id: number, permissionCallback: (permission: object) => void) => async (dispatch: any) => {
    const { response, status }: any = await useAxios({
      method: "get",
      url: `/contacts/personalinfo/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });

    if (status === 200) {
      permissionCallback(response.responseData.permissions)
      await dispatch({
        type: GET_PERSONAL_INFORMATION,
        payload: response.responseData,
      });
    }
    return;
  };

export const getContactInformation: any =
  (id: number) => async (dispatch: any) => {
    const { response, status }: any = await useAxios({
      method: "get",
      url: `/contacts/client/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });

    if (status === 200) {
      await dispatch({
        type: GET_CONTACT_INFORMATION,
        payload: response.responseData,
      });
    }
    return;
  };
export const getBrokerInformation: any =
  (id: number) => async (dispatch: any) => {
    const { response, status }: any = await useAxios({
      method: "get",
      url: `/contacts/brokerinfo/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });

    if (status === 200) {
      await dispatch({
        type: GET_BROKER_INFORMATION,
        payload: response.responseData,
      });
    }
    return;
  };
export const getSpouseInformation: any =
  (id: number) => async (dispatch: any) => {
    const { response, status }: any = await useAxios({
      method: "get",
      url: `/contacts/spouseinfo/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });

    if (status === 200) {
      await dispatch({
        type: GET_SPOUSE_INFORMATION,
        payload: response.responseData,
      });
    }
    return;
  };
export const getIdentityInformation: any =
  (id: number) => async (dispatch: any) => {
    const { response, status }: any = await useAxios({
      method: "get",
      url: `/contacts/identityinformation/${id}`,
      headers: JSON.stringify({ accept: "*/*" }),
      body: null,
    });

    if (status === 200) {
      await dispatch({
        type: GET_IDENTITY_INFORMATION,
        payload: response.responseData,
      });
    }
    return;
  };

export const resetContactInformation: any = () => async (dispatch: any) => {
  dispatch({
    type: RESET_CONTACT_DETAIL,
  });
};

export const getAllBroker: any = () => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, loading, error, status }: any = await useAxios({
    method: "get",
    url: "/contacts/getallassignedbrokers",
    headers: JSON.stringify({ accept: "*/*" }),
    body: null,
  });

  if (status == 200) {
    await dispatch(loader.setLoader(false));
    return { response, loading, error, status };
    // await dispatch({
    // type: CREATE_ROLE,
    // payload: response.responseData
    // });
  } else {
    await dispatch(loader.setLoader(false));
  }
};

export const getCountryList: any = () => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, status }: any = await useAxios({
    method: "get",
    url: "/country",
    headers: JSON.stringify({ accept: "*/*" }),
    body: null,
  });

  if (status === 200) {
    await dispatch({
      type: GET_COUNTRY_LIST_INFORMATION,
      payload: response.responseData,
    });
  }
  return;
};


export const getStateList: any = (countryId:number) => async (dispatch: any) => {
  await dispatch(loader.setLoader(true));
  const { response, status }: any = await useAxios({
    method: "get",
    url: `/state/${countryId}`,
    headers: JSON.stringify({ accept: "*/*" }),
    body: null,
  });

  if (status === 200) {
    await dispatch({
      type: GET_STATE_LIST_INFORMATION,
      payload: response.responseData,
    });
  }
  return;
};


export const contactFilterChangeHandler: any = (filter: any) => {
  return {
      type: CONTACT_CHANGE_FILTER,
      payload: filter,
  };
};

export const contactDataStateChangeHandler: any = (state: any) => {
  return {
      type: CONTACT_CHANGE_DATA_STATE,
      payload: state,
  };
};
