import React, { useEffect } from "react";
import { updateNotification } from "../redux/action/notification";
import { INotification, fetchNotifications } from "../service/notification";
import { useDispatch, useSelector } from "react-redux";
import { useKnockFeed } from "@knocklabs/react-notification-feed";

const NotificationProvider = () => {
    const dispatch = useDispatch();
    const { feedClient } = useKnockFeed();
    const { notifications, totalUnread } = useSelector(
      (state: any) => state.notification
    );
  
    const setNotification = (notifications: any) => {
      dispatch(
        updateNotification({
          notifications,
        })
      );
    };
  
    const setTotalUnread = (totalUnread: any) => {
      dispatch(
        updateNotification({
          totalUnread,
        })
      );
    };
    const onNotificationsReceived = async (resp: any) => {
      try {
        const { items } = resp;
        if (Array.isArray(items)) {
          const notificaionList = [...notifications];
          let notificationTemp: Array<INotification> = [];
          items.forEach(function (_, index) {
            const lastId = notificaionList[notificaionList.length - 1].id;
            const latestNotification = JSON.parse(items[index]?.data?.msg_body);
            if (latestNotification.id > lastId) {
              const exist =
                notificaionList.findIndex(
                  (not: any) => not.id === latestNotification.id
                ) !== -1;
              if (!exist) {
                notificationTemp.push(latestNotification);
              }
            }
          });
          if (notificationTemp.length > 0) {
            setNotification([...notificationTemp, ...notifications]);
            setTotalUnread(totalUnread + notificationTemp.length);
          }
        }
      } catch (e) {
        console.log("notification : 56 - Exception at", e);
      }
    };
  
    useEffect(() => {
      // Receive all real-time notifications on our feed
      feedClient.on("items.received.realtime", onNotificationsReceived);
  
      // Cleanup
      return () =>
        feedClient.off("items.received.realtime", onNotificationsReceived);
    }, [feedClient]);
  
    useEffect(() => {
      fetchNotifications(20, 0, false, (notifications, totalUnread, totalLength) => {
        dispatch(updateNotification({ notifications, totalUnread, totalLength, primaryNotifications: notifications }));
      });
    }, []);
  
    return <React.Fragment></React.Fragment>;
};

export default NotificationProvider;