import * as type from "../../constant/redux-const";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";

const initialState: any = {
  promotionFilter: {
    promotionName: "",
    startDate: "",
    endDate: "",
    promotionType: "",
    status: "",
  },
  promotionFilterloaded: false,
  dataState: {
    filter: {
      filters: [],
      logic: "and",
    },
    sort: [{ field: "id", dir: "desc" }],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },

  agrementFilter: {
    cardType: "",
    clientName: "",
    brokers: "",
    displayName: "",
    startCreatedDate: "",
    endCreatedDate: "",
  },
  agrementFilterloaded: false,
  agrementDataState: {
    filter: {
      filters: [],
      logic: "and",
    },
    sort: [{field: "jetcardid", dir: "desc"}],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
};

const JetCardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.JETCARD_PROMOTION_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        promotionFilter: {...action.payload},
      };
    case type.JETCARD_FILTER_LOADED: 
      return {
        ...state,
        dataState: action.payload,
      }
    case type.JETCARD_AGREMENT_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        agrementFilter: {...action.payload},
      };
    case type.JETCARD_AGREMENT_FILTER_LOADED: 
      return {
        ...state,
        agrementDataState: action.payload,
      }
    default:
      return state;
  }
};

export default JetCardReducer;
