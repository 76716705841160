import moment from "moment";
import momentTZ from "moment-timezone"
import {isEmpty} from 'lodash'
export const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;


export enum OUTPUT_DATE_FORMAT {
   SHORT_DATE = "MMM DD,yyyy",
   MONTH_DATE_YEAR = "MM/DD/yyyy",
   FULL_DATE_AND_TIME = 'MMM D, YYYY h:mm A',
   SERVER_DATE = "yyyy-MM-DD"
}

export enum INPUT_FORMAT {
   DATE_SPACE_TIME = "MM-DD-yyyy HH:mm:ss",
   SHORT_DATE = "MM-DD-yyyy",
   DATE_FIRST = "DD-MM-yyyy",
   SHORT_DATE_MONTH_FIRST = "MMM DD, yyyy",
   BLANK = "",
   TIMESTAMP_DATE = "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
}

interface IConvertTimezone {
   date: string,
   inputFormat: INPUT_FORMAT,
   outputFormat: OUTPUT_DATE_FORMAT,
   isEST?: boolean,
   timeZone?: string,
}

export const DateFormate = (t: any) => {
   let a = [{ month: 'short' }, { day: 'numeric' }, { year: 'numeric' }];
   function format(m: any) {
      let f = new Intl.DateTimeFormat('en', m);
      return f.format(t);
   }
   let data = a.map(format)
   data.splice(1, 0, ",")
   return data.join(" ");
}

export const formateDate = (dateMilli: Date) => {
   var d = (new Date(dateMilli) + '').split(' ');
   d[2] = d[2] + ',';

   return [d[1], d[2], d[3]].join(' ');
}

export const getFormattedDate = (date: any) => {
   var year = date.getFullYear();

   var month = (1 + date.getMonth()).toString();
   month = month.length > 1 ? month : '0' + month;

   var day = date.getDate().toString();
   day = day.length > 1 ? day : '0' + day;

   return month + '/' + day + '/' + year;
}

export const removeTrimeStartFormate = (item: string) => {
   return item?.trimStart();
}

export const removeTrimeEndFormate = (item: string) => {
   return item?.trimStart();
}

export const NewDateFormate = (date: any) => {
   var year = date?.getFullYear();

   var month = (1 + date.getMonth()).toString();
   month = month?.length > 1 ? month : '0' + month;

   var day = date?.getDate().toString();
   day = day?.length > 1 ? day : '0' + day;

   return month + '-' + day + '-' + year;
}

export const convertTimezone = ({ date, inputFormat, outputFormat, isEST = true, timeZone }: IConvertTimezone) => {
   if(isEmpty(date)) {
      return "";
   }
   const utcTimezone = moment.utc(date, Boolean(inputFormat) ? inputFormat : undefined);
   const localTimezone = isEST ? momentTZ(utcTimezone).tz('America/New_York') : moment(utcTimezone).local();
   return localTimezone.format(outputFormat);
}

export const convertDateTimeFromUTCToLocal = (date: moment.MomentInput, time: moment.MomentInput) => {
    if (!(date && time)) {
        return null;
    }
    const utcDateTime = moment.utc(date + ' ' + time, 'yyyy-MM-DD HH:mm:ss').toDate();
    return moment(utcDateTime).local();
};

export const convertDateTimeFromUTCToLocalForISOString = (dateTime: moment.MomentInput) => {
    if (!dateTime) {
        return null;
    }
    const utcDateTime = moment.utc(dateTime).toDate();
    return moment(utcDateTime).local();
};

export const convertDateTimeFromLocalToUTC = (date: moment.MomentInput, time: moment.MomentInput) => {
    if (!(date && time && moment(date).isValid() && moment(time).isValid())) {
        return null;
    }
    const dateOnly = moment(date).format("MM-DD-yyyy");
    const timeOnly = moment(time).format("HH:mm:ss");
    return moment(dateOnly + ' ' + timeOnly, 'MM-DD-yyyy HH:mm:ss').utc();
};

// export const convertDateTimeFromTimezoneToUTC = (date: moment.MomentInput, time: moment.MomentInput, timezone: string) => {
//     if (!(date && time && moment(date).isValid() && moment(time).isValid())) {
//         return null;
//     }
//     const dateOnly = moment(date).format("MM-DD-yyyy");
//     const timeOnly = moment(time).format("HH:mm:ss");
//     const dateTimeWithTimezone = momentTZ.tz(dateOnly + ' ' + timeOnly, 'MM-DD-yyyy HH:mm:ss', timezone)
//     return dateTimeWithTimezone.utc();
// };

// export const convertDateTimeFromUTCToTimezone = (date: moment.MomentInput, time: moment.MomentInput, timezone: string) => {
//     if (!(date && time)) {
//         return null;
//     }
//     const utcTimezone = moment.utc(date + ' ' + time, 'MM-DD-yyyy HH:mm:ss');
//     const dateTimeWithTimezone = utcTimezone.clone().tz(timezone);
//     return dateTimeWithTimezone;
// };

export const convertMinutesInHourAndMinutes = (minutes: number) => {
    const durationHours = Math.floor(minutes / 60);
    const durationMinutes = minutes % 60;
    return {
        durationHoursString: ("0" + durationHours).slice(-2),
        durationMinutesString: ("0" + durationMinutes).slice(-2)
    }
};

export const extractQueryAsJSONFromURL = (searchString: string) => {
    const search = searchString.substring(1);
    return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', (key, value) => {
        return key === "" ? value : decodeURIComponent(value);
    });
} 