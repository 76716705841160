// import { i18nReducer } from 'redux-react-i18n';
import { combineReducers } from 'redux';
import AuthReducer from './auth-reducer';
import ContactReducer from './contact-reducer';
import LoaderReducer from './loader-reducer';
import ToastNotificationReducer from './ToastNotificationReducer';
import PostReducer from './urt-reducer';
import PassengerRducer from './passenger-reducer';
import JetCardReducer from './jetcard-reducer';
import GlobalSearchReducer from './globalSearch-reducer';
import { RESET_LOGIN_USER_DATA } from '../../constant/redux-const';
import TripReducer from './trip-reducer';
import formikDataReducer from './formik-data-reducer';
import formikManageTripReducer from '../formik/trip-reducer';
import NotificationReducer from './notification-reducer';
import OperatorReducer from './operator-reducer';

/** Add Multiple Reducer */
const reducers = {
  commonReducer: LoaderReducer,
  posts: PostReducer,
  loader:LoaderReducer,
  toast: ToastNotificationReducer,
  contacts:ContactReducer,
  auth: AuthReducer,
  passenger:PassengerRducer,
  jetCard: JetCardReducer,
  trip: TripReducer,
  globalSearch: GlobalSearchReducer,
  formikData: formikDataReducer, 
  formikManageTrip: formikManageTripReducer, 
  notification: NotificationReducer,
  operator: OperatorReducer,
};

const rootReducer = combineReducers(reducers);
export default (state: any, action: any) => rootReducer(action.type === RESET_LOGIN_USER_DATA ? undefined : state, action);
