import * as type from '../../constant/redux-const';
import { DEFAULT_PAGE_SIZE } from '../../utils/constant';

const initialTripFilter = {
    tripDisplayName: "",
    clientName: "",
    broker: [],
    tripStartDate: "",
    tripEndDate: "",
    operatorName: [],
    tripStatus: [],
    tripType: "",
    balanceDue: true,
    revisedCost: "",
    splitTrip: "",
};

const initialState: any = {
  operatorList: [],
  operatorFilter: initialTripFilter,
  dataState: {
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
    sort: [{ field: "tripId", dir: "desc" }],
    filter: {filters: [{field: "balanceDue", operator: "neq", value: "0"}], logic: "and"}
  }
};

const OperatorReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case type.UPDATE_OPERATOR_FINANCE_LIST:
            return {
                ...state,
                operatorList: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_FILTER:
            return {
                ...state,
                operatorFilter: action.payload
            }
        case type.UPDATE_OPERATOR_FINANCE_LIST_DATA_STATE:
            return {
                ...state,
                dataState: action.payload
            }
        default:
            return state;
    }
}


export default OperatorReducer;
