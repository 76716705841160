import AxiosService from "./Service";
import { URLS, addQueryParameters, buildAPIURL } from "../utils/url";

export interface INotification {
  id: number;
  userId: number;
  notificationEntityTypeId: number;
  message: string;
  visitedDate: string;
  createdDate: string;
  updatedDate: string;
  createdBy: number;
  updatedBy: number;
  entityId: number;
  isVisited: boolean;
  deleted: boolean;
  createdByName: string;
}

export const fetchNotifications = async (
  size: number,
  page: number,
  onlyUnread: boolean = false,
  callback: (
    notifications: Array<INotification>,
    totalUnread: number,
    totalLength: number
  ) => void
) => {
  let URL = addQueryParameters(URLS.GetNotifications, {
    size, page
  });
  if (onlyUnread) {
    URL += `&isVisited=${!onlyUnread}`;
  }
  const { response, status } = await AxiosService({
    url: URL,
    method: "get",
  });
  callback(
    response?.responseData?.page?.content ?? [],
    response?.responseData?.unreadNotificationCount ?? 0,
    response?.totalRecords
  );
};


export const markAllAsRead = async () => {
  return await AxiosService({
    url: URLS.VisitNotification,
    method: "put",
    body: JSON.stringify({
      ids: [],
      clearAll: true,
    }),
  });
};
