import { CONTACT_DETAILS_TAB_LIST } from "../pages/contact/contactDetails";
import { TRIP_DETAILS_TAB_LIST } from "../pages/trip/tripDetails";

export const ROUTE_URLS = {
    FINANCE_TRIP_ADMINISTRATION: '/finance/trip',
    CONTACT_DETAILS: '/contact/:contactId',
    CONTACT_DETAILS_TRIPS_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.TRIPS}`,
    CONTACT_DETAILS_JETCARD_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.JET_CARDS}`,
    CONTACT_DETAILS_JETCARD_TAB_WITH_ID: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.JET_CARDS}/:jetCardId`,
    CONTACT_DETAILS_PASSENGERS_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.PASSENGERS}`,
    CONTACT_DETAILS_TASKS_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.TASKS}`,
    CONTACT_DETAILS_NOTES_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.NOTES}`,
    CONTACT_DETAILS_WEBINQUIRIES_TAB: `/contact/:contactId/${CONTACT_DETAILS_TAB_LIST.WEB_INQUIRIES}`,
    TRIP: '/trip',
    TRIP_DETAILS: '/trip/:tripId',
    TRIP_DETAILS_PASSENGERS_TAB: `/trip/:tripId/${TRIP_DETAILS_TAB_LIST.PASSENGERS}`,
    TRIP_DETAILS_FINANCES_TAB: `/trip/:tripId/${TRIP_DETAILS_TAB_LIST.FINANCES}`,
    TRIP_DETAILS_TASKS_TAB: `/trip/:tripId/${TRIP_DETAILS_TAB_LIST.TASKS}`,
    TRIP_DETAILS_NOTES_TAB: `/trip/:tripId/${TRIP_DETAILS_TAB_LIST.NOTES}`,
    TRIP_DETAILS_ACTIVITY_HISTORY_TAB: `/trip/:tripId/${TRIP_DETAILS_TAB_LIST.ACTIVITY_HISTORY}`,
    CREATE_TRIP: '/trip/create-trip',
    EDIT_TRIP: '/trip/:tripId/edit',
    TRIP_PROPOSAL_PREVIEW_BROKER: '/trip/:tripId/proposal/preview',
    TRIP_PROPOSAL_PREVIEW_CLIENT: '/trip/:tripId/:hash/proposal-review',
    TRIP_PROPOSAL_PREVIEW_CLIENT_RECIPIENT: '/trip/:tripId/:hash/proposal-review-recipient',
    TRIP_PROPOSAL_PRELIMINARY_INVOICE_CLIENT: '/trip/:tripId/:hash/preliminary-invoice',
    TRIP_PROPOSAL_INVOICE_CLIENT: '/trip/:tripId/:hash/invoice',
    TRIP_FEEDBACK_CLIENT: '/trip/:tripId/submit-feedback/:hash',
    TRIP_FEEDBACK_BROKER: '/trip/:tripId/feedback-review',
    MANAGE_TRIP: '/trip/manage-trip/:tripId',
    // Finance
    FINANCE: '/finance',
    FINANCE_OPERATOR_PAYMENT: '/finance/operator-payment',
    // Tasks
    TASKS: '/tasks',
};

export const buildURL = (url: string, params: Record<string, any>) => {
    Object.keys(params).forEach(k => {
        url = url.replace(`:${k}`, params[k]);
    });
    return url;
}