export enum LOCALSTORAGE_KEY {
    TOKEN = "token",
    REFRESH_TOKEN = "refresh_token",
    AD_TOKEN = "ad_token",
    LAST_API_TIMESTAMP = "last_api_timestamp"
}

export const isUserAuthenticated = () => {
    return localStorage.getItem(LOCALSTORAGE_KEY.TOKEN)
}

export const getLocalStorageByKey = (key: string) => {
    return localStorage.getItem(key)
}

export const setLocalStorageByKey = (key: string, token: string) => {
    localStorage.setItem(key, token)
}

export const clearAll = () => {
    localStorage.clear()
}