import AxiosService from "./Service";
import { URLS, buildAPIURL } from "../utils/url";
import { AIRCRAFT_TYPE_KEY } from "../constant/enum";

// need to add more based on requirement
export interface IContact {
    id: number;
    clientEmail: string;
    clientName: string;
    broker: string;
}
export interface IContactList {
    total: number;
    aggregates: unknown;
    data: Array<IContact>;
}
export const fetchContactWithFilter = async (payload: object, callback: (contactList: IContactList | null, isError: boolean) => void) => {
    const result = await AxiosService({
        method: "post",
        body: JSON.stringify(payload),
        url: buildAPIURL(URLS.GetContacts)
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
};

export interface IEmail {
    id: number,
    clientId: number,
    email: string,
    emailType: string,
    otherTypeName?: string | null,
    preferred: boolean,
    deleted: boolean,
}

export const fetchEmailsByClientId = async (clientId: number, callback: (emailList: Array<IEmail>) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetClientEmails) + clientId
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
};

interface IBrokerAndAssistantInfo {
    id: number;
    fullName: string;
}
export interface IBrokerAndAssistantInfoList {
    assistant: {
        assistant: Array<IBrokerAndAssistantInfo>
    }
    broker: {
        brokers: Array<IBrokerAndAssistantInfo>
    }
}
export const fetchBrokerAndAssistantByClientId = async (clientId: number, callback: (brokerAndAssistantInfoList: IBrokerAndAssistantInfoList) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetClientBrokers) + clientId
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
};

interface rateDTOS {
    id: number;
    aircraftTypeId: number;
    aircraftType: AIRCRAFT_TYPE_KEY;
    rate: number;
    minDailyHours: number;
    preferred: boolean;
}
// need to add more based on requirement
export interface IJetCard {
    jetcardId: number;
    jetCardDisplayId: string;
    cardType: {id: number; displayName: string};
    cardStatus: {id: number; displayName: string};
    balance: number;
    rateDTOS: Array<rateDTOS>;
    balanceHours: number;
    hoursUsed: number;
    totalHours: number;
    createdDate: string;
}
export const fetchJetsCardByClientId = async (clientId: number, callback: (jetCardList: Array<IJetCard>) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetContactJetCardList) + `/${clientId}`
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
};

// need to add more based on requirement
interface IPassengerDetail {
    firstName: string;
    lastName: string;
    passengerId: number;
}
export interface IPassenger {
    id: number;
    passengerDetail: IPassengerDetail;
    identityDoc: Array<Object>;
}
export const fetchPassengerByClientId = async (clientId: number, callback: (passengerList: Array<IPassenger>) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetClientPassenger) + clientId
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
};

export interface IClientNotes {
    id: number;
    note: string
    pinned: boolean;
    private: boolean;
    active: boolean;
    deleted: boolean;
    jetCardId: null
    jetCardDisplayId: null
    jetsClientId: number
    tripId: number
    createdBy: number;
    createdDate: string;
    modifiedBy: number;
    modifiedDate: string;
}
export const fetchClientNotesClientId = async (clientId: number, callback: (clientNotesList: Array<IClientNotes>) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetContactNotesByClientId) + `/${clientId}`
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
};

export const fetchContactDetails = async(clientId: any) => {
    return await AxiosService({
        method: "get",
        url: `/contacts/client/${clientId}`,
        headers: JSON.stringify({ accept: "*/*" }),
        body: null,
      });
}

export const fetchTripCountByClientId = async(clientId: any, callback: (response: any) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetTripCount, {clientId})
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData);
    }
}