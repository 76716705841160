import "../src/Assets/Styles/common.scss";
import "../src/Assets/Styles/Maverick.scss";
import "../src/Assets/Styles/main.scss";
import "../src/Assets/Styles/Maverick-responsive.scss";
import "./Assets/Styles/kendo-icons.css"
import React from "react";
import { IconsContext } from '@progress/kendo-react-common';
import { BrowserRouter } from "react-router-dom";
import { useClearCache } from 'react-clear-cache';
import Navigation from "./navigation/navigation";
import { ViewportProvider } from "./hooks/useViewPort";
import { ThemeProvider } from "./theme/Theme";
import ErrorBoundary from "./pages/unAuthorized/ErrorBoundary";

const App: React.FC<{}> = () => {
    const { isLatestVersion, emptyCacheStorage, latestVersion } = useClearCache();

    React.useEffect(() => {
        let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
        if (isJiraEnabled === "true") {
            let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
            const script = document.createElement("script");
            script.src = `${src}`;
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    React.useEffect(() => {
        if (!isLatestVersion) {
            emptyCacheStorage();
        }
    }, [isLatestVersion, latestVersion, emptyCacheStorage]);

    return (
        <ErrorBoundary>
            <IconsContext.Provider value={{ type: 'svg' }}>
                <ThemeProvider>
                    <BrowserRouter>
                        <ViewportProvider>
                            <Navigation/>
                        </ViewportProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </IconsContext.Provider>
        </ErrorBoundary>
    );
}
export default App;
